import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const CardWrapper = styled.div`
  blockquote {
    color: #ffffef;
  }

  .card-image {
    width: 100%;
    height: 300px;
  }
  img {
    object-fit: cover;
    object-position: top center;
  }
  @media only screen and (max-width: 600px) {
    margin: 1em;
  }
`

const IndexPage = () => {
  const imageQuery = useStaticQuery(graphql`
    {
      cardImage: allFile(filter: { relativeDirectory: { eq: "press" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const cardImage = imageQuery.cardImage.edges

  return (
    <Layout>
      <SEO title="Home" />
      <CardWrapper>
        <blockquote>
          <h4>
            <a href="https://www.kaltblut-magazine.com/salford-x-namesldn-lfw-s-s20/">
              SALFORD X NAMESLDN: LFW S/S20 - KALTBLUT Magazine
            </a>
          </h4>
          {console.log(cardImage)}
          <Img
            className="card-image"
            fluid={cardImage[0].node.childImageSharp.fluid}
            imgStyle={{ objectPosition: "top center" }}
          ></Img>
          <p>
            The University of Salford, Manchester UK, presented its first ever
            graduate show at Somerset House during London Fashionweek, courtesy
            of NAMESldn. Held in the Lancaster Rooms, the live runway show
            debuted 13 talented designers with 32 models and over 100 looks,
            featuring football fanatics, glam rock and rollers, anime
            enthusiasts and a streetwear heavy gang of tomorrow.
          </p>
        </blockquote>
      </CardWrapper>
    </Layout>
  )
}

export default IndexPage
